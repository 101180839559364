import React from 'react'
import './CardDepoimento.css'

export default props =>
    <div className="card-depoimento text-center">
        <div className="cliente-imagem">
            <img src={props.imagem} className="img-fluid rounded-circle" alt="Imagem dos clientes" />
        </div>
        <h6 className="nome-cliente mt-4">
            {props.nome}
        </h6>
        <div className="cargo">
            {props.cargo}
        </div>
        <p className="texto-depoimento mt-4">
            {`"${props.depoimento}"`}
        </p>
    </div>