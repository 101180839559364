import React from 'react'
import './Totalizador.css'

//Dependências Internas
import ImagemVideoWEBP from '../../assets/images/carrorama-video-numeros.webp'
import ImagemVideoPNG from '../../assets/images/carrorama-video-numeros.png'
import ImagemVideoJP2 from '../../assets/images/carrorama-video-numeros.jp2'


//Dependências Externas
import CountUp from 'react-countup'
import PlayerVideo from './player/PlayerVideo'
import 'animate.css'
import ReactWOW from 'react-wow'

export default props =>
    <section className="totalizador padding-section">
        <div className="container">

            <div className="row">
                <div className="col-md-12">
                    <div className="desc-totalizador text-center">
                        <ReactWOW animation='fadeIn'>
                            <p>Nós queremos que você tenha tempo livre para se dedicar ao que realmente importa.
                                    Deixe suas manutenções, despesas, vencimento de seguro, CNH ou multas, por nossa conta.
                                </p>
                        </ReactWOW>
                    </div>
                </div>
            </div>

            <hr />
            <div className="row d-flex align-items-center">
                <div className="col-md-6">
                    <ReactWOW animation='slideInUp'>
                        <div className="row totalizador-group mb-4">
                            <div className="col-8 col-md-9 text-right">
                                <div className="text-totalizador">

                                    <div className="title">VEÍCULOS GERENCIADOS</div>
                                    <CountUp separator="." end={232226} duration={5} />
                                </div>
                            </div>
                            <div className="col-4 col-md-3 text-center">
                                <div className="icon-total">
                                    <i className="fa fa-car" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                    <ReactWOW animation='slideInUp'>
                        <div className="row totalizador-group mb-4">
                            <div className="col-8 col-md-9 text-right">
                                <div className="text-totalizador">
                                    <div className="title">USUÁRIOS</div>
                                    <CountUp separator="." end={204253} duration={5} />
                                </div>
                            </div>
                            <div className="col-4 col-md-3 text-center">
                                <div className="icon-total">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                    <ReactWOW animation='slideInUp'>
                        <div className="row totalizador-group mb-4">
                            <div className="col-8 col-md-9 text-right">
                                <div className="text-totalizador">
                                    <div className="title">TOTAL ECONOMIZADO</div>
                                    R$ <CountUp separator="." decimals={2}
                                        decimal="," end={897120.83} duration={5} />
                                </div>
                            </div>
                            <div className="col-4  col-md-3 text-center">
                                <div className="icon-total">
                                    <i className="fas fa-money-bill-alt"></i>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                    <ReactWOW animation='slideInUp'>
                        <div className="row totalizador-group mb-4">
                            <div className="col-8 col-md-9 text-right">
                                <div className="text-totalizador">
                                    <div className="title">CUSTOS MONITORADOS</div>
                                    R$ <CountUp separator="." decimals={2}
                                        decimal="," end={2990402.76} duration={5} />
                                </div>
                            </div>
                            <div className="col-4 col-md-3 text-center">
                                <div className="icon-total">
                                    <i className="fa fa-thermometer-three-quarters" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                </div>

                <div className="col-md-6">
                    <ReactWOW animation='slideInUp' duration={5000}>
                        <div>
                            <picture>
                                <source type="image/webp" srcSet={ImagemVideoWEBP} alt="Computador de fundo com plantas" />
                                <source type="image/jp2" srcSet={ImagemVideoJP2} alt="Computador de fundo com plantas" />
                                <img src={ImagemVideoPNG} alt="Computador de fundo com plantas" />
                            </picture>
                            <PlayerVideo />
                        </div>
                    </ReactWOW>
                </div>
            </div>
        </div>
    </section >