import React from 'react'
import './Faq.css'

//Dependências internas
import Accordion from './accordion/Accordion'
import FaqImageWEBP from '../assets/images/carrorama-duvida.webp'
import FaqImagePNG from '../assets/images/carrorama-duvida.png'
import FaqImageJP2 from '../assets/images/carrorama-duvida.jp2'
import Header from '../header/Header'
import Contato from '../contato/Contato'
import Footer from '../footer/Footer'

// Depedências Externas
import 'animate.css'
import ReactWOW from 'react-wow'

export default props =>
    <section className="faq-page">
        <Header />
        <div className="banner">
            <div className="banner-text">
            </div>
        </div>
        <section className="faq padding-section" id="faq">
            <div className="container">
                <ReactWOW animation='fadeIn'>
                    <div className="text-center title-group mb-5">
                        <h3>Dúvidas Frequentes</h3>
                        <hr className="divisor" />
                        <p>Ainda tem dúvida sobre o Carrorama? Veja algumas dúvidas mais comuns:</p>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInUp'>
                    <div className="row mb-5">
                        <div className="col-md-6 pr-5 d-flex align-items-center mb-5">
                            <picture>
                                <source type="image/webp" srcSet={FaqImageWEBP} alt="carrorama-faq" />
                                <source type="image/jp2" srcSet={FaqImageJP2} alt="carrorama-faq" />
                                <img src={FaqImagePNG} alt="carrorama-faq" />
                            </picture>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <Accordion />
                        </div>
                    </div>
                </ReactWOW>
            </div>
        </section>
        <Contato />
        <Footer />
    </section>