import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

//Dependências Internas
import Home from './components/home/Home'
import Sobre from './components/sobre/Sobre'
import Planos from './components/planos/Planos'
import FAQ from './components/faq/Faq'
import LoginPerfil from './components/login-perfil/LoginPerfil'

export default props => 
    <BrowserRouter>
        <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/home" exact={true} component={Home} />
            <Route path="/contato" exact={true} component={Home} />
            <Route path="/sobre-nos" exact={true} component={Sobre} />
            <Route path="/planos" exact={true} component={Planos} />
            <Route path="/faq" exact={true} component={FAQ} />
            <Route path="/obrigado" exact={true} component={Home} />
            <Route path="/home/obrigado" exact={true} component={Home} />
            <Route path="/contato/obrigado" exact={true} component={Home} />
            <Route path="/sobre-nos/obrigado" exact={true} component={Sobre} />
            <Route path="/planos/obrigado" exact={true} component={Planos} />
            <Route path="/faq/obrigado" exact={true} component={FAQ} />
            <Route path="/painel" exact={true} component={LoginPerfil} />
            <Route path='/help' component={() => {
                window.location.href = 'https://going2mobile.atlassian.net/servicedesk/customer/portal/6';
                return null;
            }} />
            <Route path='/ajuda' component={() => {
                window.location.href = 'https://going2mobile.atlassian.net/servicedesk/customer/portal/6';
                return null;
            }} />
        </Switch>
    </BrowserRouter>