import React from 'react'
import './VerticalTimeline.css'

//Dependências Externas
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

export default props =>
    <VerticalTimeline>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(247, 205, 36)' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(247, 205, 36)' }}
            date="2012 – 2013"
            iconStyle={{ background: 'rgb(247, 205, 36)' }}
            icon={<i className="fas fa-mobile-alt"></i>}
        >
            <h3 className="vertical-timeline-element-title">CARRORAMA APP</h3>
            <h4 className="vertical-timeline-element-subtitle">IOS, Android</h4>
            <p>
                Nasce o primeiro app Brasileiro para controle de despesas, focado no gerenciamento de manutenções,
                vencimento de seguro, CNH, multas e outras despesas, focado para o publico B2C.
                Baixe o APP Grátis
            </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2013 – 2014"
            contentStyle={{ borderTop: '3px solid rgb(247, 205, 36)' }}
            iconStyle={{ background: 'rgb(247, 205, 36)' }}
            icon={<i className="fab fa-bluetooth-b"></i>}
        >
            <h3 className="vertical-timeline-element-title">CARRORAMA OBD</h3>
            <h4 className="vertical-timeline-element-subtitle">Android</h4>
            <p>
                Nasce o primeiro app Brasileiro com tecnologia OBD Bluethoot IoT embarcada,
                através do smartphone o usuário passa a ter um completo computador de bordo, capaz de sensores e detectar falhas em seu veículo.
                Baixe o app grátis
            </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2014 – 2016"
            contentStyle={{ borderTop: '3px solid rgb(247, 205, 36)' }}
            iconStyle={{ background: 'rgb(247, 205, 36)' }}
            icon={<i className="fas fa-laptop"></i>}
        >
            <h3 className="vertical-timeline-element-title">
                CARRORAMA FLEET PLANO STARTER
            </h3>
            <p>
                Após o grande sucesso, durante o salão do automóvel , e a procura do Carrorama App para o corporativo B2B, nasce nosso erp 100% voltado para a gestão de frotas.
            </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2016 – 2018"
            contentStyle={{ borderTop: '3px solid rgb(247, 205, 36)' }}
            iconStyle={{ background: 'rgb(247, 205, 36)' }}
            icon={<i className="fas fa-map-marked-alt"></i>}
        >
            <h3 className="vertical-timeline-element-title">
                CARRORAMA FLEET PLANO ELITE
            </h3>
            <p>
                Tudo o que o CarroramaFleet – Plano Starter tem de melhor,
                integrado a um dispositivo OBD m2m IoT, capaz de fornecer telemetria em tempo real para tomada de decisões da sua frota.
            </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
            iconStyle={{ background: '#2b2f38', color: '#fff' }}
            icon={<i className="fas fa-car-side"></i>}
        />

    </VerticalTimeline>