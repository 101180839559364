import React from 'react'
import './Contato.css'

//Dependências Internas
import Form from './form/Form'

//Dependências Externas
import 'animate.css'
import ReactWOW from 'react-wow'

export default props =>
    <section className="contato padding-section" id="contato">
        <div className="container">
            <ReactWOW animation='fadeIn'>
                <div className="text-center title-group mb-5">
                    <h3>Contato</h3>
                    <hr className="divisor" />
                    <p>Tenha controle de toda sua frota em alguns cliques.</p>
                </div>
            </ReactWOW>
            <ReactWOW animation='slideInUp'>
                <Form />
            </ReactWOW>
        </div>
    </section>