import React from 'react'
import './Accordion.css'

export default props =>
    <div className="accordion" id="accordionFaq">
        <div className="card">
            <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        O sistema consegue identificar o condutor responsável pela multa?
                    </button>
                </h5>
            </div>

            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionFaq">
                <div className="card-body">
                    Durante o cadastro da infração, o sistema faz uma leitura do banco de dados das viagens cadastradas pelos condutores, no aplicativo. Identificando quem é condutor responsável pela infração naquele determinado horário e dia.
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="headingTwo">
                <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Vocês disponibilizam aplicativo para os condutores cadastrarem abastecimento durante viagens ou serviço externo?
                    </button>
                </h5>
            </div>
            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionFaq">
                <div className="card-body">
                    Sim. Após a contratação, é disponibilizado o acesso web aos gestores e aplicativo aos condutores. No aplicativo, os condutores poderão cadastrar despesas de abastecimento, pedágio e estacionamento. Inclusive, anexar imagens de cupom fiscal ou comprovantes
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="headingThree">
                <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Consigo analisar o histórico de manutenção da troca de óleo de um veículo?
                    </button>
                </h5>
            </div>
            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionFaq">
                <div className="card-body">
                    Sim. É possível que o gestor analise o histórico de determinado item do grupo de manutenção, visualizando quem foi o fornecedor, valor, data, quantidade, das ordens de serviços anterior, conseguindo até mesmo identificar se o item esta dentro da garantia.
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="headingFour">
                <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Pelo aplicativo, o condutor consegue fazer um check list antes de iniciar um serviço externo?
                    </button>
                </h5>
            </div>
            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionFaq">
                <div className="card-body">
                    Para iniciar um serviço externo/viagem, é necessário que o condutor preencha um check list de avarias, tendo a opção de registrar anexos para comprovação da informação.
                </div>
            </div>
        </div>
    </div>