import React from 'react'
import './Sobre.css'

//Dependências Internas
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Timeline from './timeline/VerticalTimeline'
import Contato from '../contato/Contato'

import imgSobreWEBP from '../assets/images/img-sobre.webp'
import imgSobrePNG from '../assets/images/img-sobre.png'
import imgSobreJP2 from '../assets/images/img-sobre.jp2'

import going2LogoWEBP from '../assets/images/going2.webp'
import going2LogoPNG from '../assets/images/going2.png'
import going2LogoJP2 from '../assets/images/going2.jp2'

// Depedências Externas
import 'animate.css'
import ReactWOW from 'react-wow'

export default props =>
    <section className="sobre-nos">
        <Header />
        <div className="banner">
            <div className="banner-text">
            </div>
        </div>
        <div className="container padding-section">
            <div className="row">
                <div className="col-md-6 d-flex align-items-center mb-5">
                    <ReactWOW animation='fadeInUp'>
                        <div>
                            <div className="text-center title-group mb-5">
                                <h3>Sobre Nós</h3>
                                <hr className="divisor" />
                            </div>
                            <p className="text-sobre-nos">
                                Carrorama®, é uma marca registrada Going2 Corporation S/A.
                                Fundada em 2012, a Going2 Corporation S/A é uma empresa de tecnologia da informação,
                                focada em criar produtos que tenham como premissa facilitar a vida das pessoas e empresas,
                                através do desenvolvimento de Aplicativos, Sistemas e dos princípios de IOT (internet das coisas).
                        </p>
                            <a href="https://going2.com.br/" rel="noopener noreferrer" target="_blank">
                                <picture>
                                    <source type="image/webp" srcSet={going2LogoWEBP} alt="Going2 Logo" />
                                    <source type="image/jp2" srcSet={going2LogoJP2} alt="Going2 Logo" />
                                    <img src={going2LogoPNG} alt="Going2 Logo" />
                                </picture>
                            </a>
                        </div>
                    </ReactWOW>
                </div>
                <div className="col-md-6">
                    <ReactWOW animation='fadeInUp'>
                        <picture>
                            <source type="image/webp" srcSet={imgSobreWEBP} alt="Imagem Sobre Nós" />
                            <source type="image/jp2" srcSet={imgSobreJP2} alt="Imagem Sobre Nós" />
                            <img src={imgSobrePNG} alt="Imagem Sobre Nós" className="img-sobre" />
                        </picture>
                    </ReactWOW>
                </div>
            </div>
        </div>
        <section className="timeline-carrorama">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <Timeline />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Contato />
        <Footer />
    </section>