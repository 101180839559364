import React from 'react'
import './Planos.css'

//Dependências Internas
import Header from '../header/Header'
import Footer from '../footer/Footer'

//Dependências Internas
import Table from './table/PlanoTable'
import Contato from '../contato/Contato'

// Depedências Externas
import 'animate.css'
import ReactWOW from 'react-wow'

export default props =>
    <section className="planos-page">
        <Header />
        <div className="banner">
            <div className="banner-text">
            </div>
        </div>
        <section className="planos padding-section" id="planos">
            <div className="container">
                <ReactWOW animation='fadeIn'>
                    <div className="text-center title-group">
                        <h3>Planos</h3>
                        <hr className="divisor" />
                        <p>Temos Planos especiais para sua Frota e ainda possibilidade de módulos adicionais.</p>
                    </div>
                </ReactWOW>
                <Table />
            </div>

        </section>
        <Contato />
        <Footer />
    </section>