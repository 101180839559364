import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@fortawesome/fontawesome-free/css/all.min.css';

import Routes from './Routes'
import WhatsButton from './components/whats-button/WhatsButton'

export default function App() {
  return (
    <>
      <WhatsButton />
      <Routes />
    </>
  )
}
