import React from "react";
import "./Clientes.css";

//Dependências Internas
import parceiroUmWEBP from "../../assets/images/parceiros/parceiro-ciet.webp";
import parceiroUmPNG from "../../assets/images/parceiros/parceiro-ciet.png";
import parceiroUmJP2 from "../../assets/images/parceiros/parceiro-ciet.jp2";

import parceiroDoisWEBP from "../../assets/images/parceiros/parceiro-premed.webp";
import parceiroDoisPNG from "../../assets/images/parceiros/parceiro-premed.png";
import parceiroDoisJP2 from "../../assets/images/parceiros/parceiro-premed.jp2";

import parceiroTresWEBP from "../../assets/images/parceiros/parceiro-sensedia-07.webp";
import parceiroTresPNG from "../../assets/images/parceiros/parceiro-sensedia-07.png";
import parceiroTresJP2 from "../../assets/images/parceiros/parceiro-sensedia-07.jp2";

import parceiroQuatroWEBP from "../../assets/images/parceiros/parceiro-txfiber.webp";
import parceiroQuatroPNG from "../../assets/images/parceiros/parceiro-txfiber.png";
import parceiroQuatroJP2 from "../../assets/images/parceiros/parceiro-txfiber.jp2";

//Dependências Externas
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const options = {
  items: 4,
  loop: true,
  nav: false,
  dots: false,
  autoplay: true,
  autoplayHoverPause: true,
  autoplaySpeed: 500,
  autoplayTimeout: 2500,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
  },
};

export default (props) => (
  <section className="clientes">
    <div className="container">
      <OwlCarousel className="owl-theme" {...options}>
        <div className="item text-center">
          <picture>
            <source
              type="image/webp"
              srcSet={parceiroUmWEBP}
              alt="Logo dos parceiros do Carrorama"
            />
            <source
              type="image/jp2"
              srcSet={parceiroUmJP2}
              alt="Imagem do aplicativo do Condutor"
            />
            <img src={parceiroUmPNG} alt="Logo dos parceiros do Carrorama" />
          </picture>
        </div>
        <div className="item text-center">
          <picture>
            <source
              type="image/webp"
              srcSet={parceiroDoisWEBP}
              alt="Logo dos parceiros do Carrorama"
            />
            <source
              type="image/jp2"
              srcSet={parceiroDoisJP2}
              alt="Imagem do aplicativo do Condutor"
            />
            <img src={parceiroDoisPNG} alt="Logo dos parceiros do Carrorama" />
          </picture>
        </div>
        <div className="item text-center">
          <picture>
            <source
              type="image/webp"
              srcSet={parceiroTresWEBP}
              alt="Logo dos parceiros do Carrorama"
            />
            <source
              type="image/jp2"
              srcSet={parceiroTresJP2}
              alt="Imagem do aplicativo do Condutor"
            />
            <img src={parceiroTresPNG} alt="Logo dos parceiros do Carrorama" />
          </picture>
        </div>
        <div className="item text-center">
          <picture>
            <source
              type="image/webp"
              srcSet={parceiroQuatroWEBP}
              alt="Logo dos parceiros do Carrorama"
            />
            <source
              type="image/jp2"
              srcSet={parceiroQuatroJP2}
              alt="Imagem do aplicativo do Condutor"
            />
            <img
              src={parceiroQuatroPNG}
              alt="Logo dos parceiros do Carrorama"
            />
          </picture>
        </div>
      </OwlCarousel>
    </div>
  </section>
);
