import React from 'react'
import './Banner.css'

//Dependências Internas
import computadoresBannerWEBP from '../../assets/images/carrorama-banner-main-cut.webp'
import computadoresBannerPNG from '../../assets/images/carrorama-banner-main-cut.png'
import computadoresBannerJP2 from '../../assets/images/carrorama-banner-main-cut.jp2'


export default props =>
    <section className="banner">
        <div className="container-fluid">
            <div className="banner-text">
                <div className="row">
                    <div className="col-lg-6 text">
                        <h1 className="text-nowrap">
                            PLATAFORMA PARA <br /><span className="color-yellow">GESTÃO DE FROTAS</span>
                        </h1>
                        <p className="text-nowrap">
                            Controle e economize com eficiência sua frota, através de gráficos e relatórios que <br /> fornecem informações sobre veículos e condutores.
                    </p>
                    </div>
                    <div className="col-lg-6">
                        <picture>
                            <source type="image/webp" srcSet={computadoresBannerWEBP} alt="computadores-dashboard" />
                            <source type="image/jp2" srcSet={computadoresBannerJP2} alt="computadores-dashboard" />
                            <img src={computadoresBannerPNG} alt="computadores-dashboard" />
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </section >