import React, { Component } from 'react'
import './Produtos.css'

//Dependências Internas
import ProdutoStarter from './starter/ProdutoStater'
import ProdutoElite from './elite/ProdutoElite'


export default class Produto extends Component {
    constructor(props) {
        super(props)
        this.state = {
            starter: true,
            elite: false,
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(event) {
        let value = event.target.value
        if (value === 'starter') {
            this.setState({
                elite: false,
                starter: true
            })
        }
        else {
            this.setState({
                elite: true,
                starter: false
            })
        }
    }

    render() {
        return (
            <section className="produtos padding-section">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-sm-6">
                            <button className="card btn-produto button-display-sm mb-4" onClick={this.handleClick} value="starter">
                                Plano Starter
                            </button>
                        </div>
                        <div className="col-6 col-sm-6">
                            <button className="card btn-produto button-display-sm" onClick={this.handleClick} value="elite">
                                Plano Elite
                               </button>
                        </div>
                        <div className="col-md-2 align-items-center button-display-lg">
                            <button className="card btn-produto" onClick={this.handleClick} value="starter">
                                Plano Starter
                            </button>
                        </div>
                        <div className="col-md-8">
                            <div className="card produto-selecionado">
                                {
                                    this.state.starter &&
                                    <ProdutoStarter />
                                }
                                {
                                    this.state.elite &&
                                    <ProdutoElite />
                                }
                            </div>

                        </div>
                        <div className="col-md-2  align-items-center button-display-lg mb-4">
                            <button className="card btn-produto" onClick={this.handleClick} value="elite">
                                Plano Elite
                               </button>
                        </div>
                    </div>
                </div>

            </section>

        )
    }
}