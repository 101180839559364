import React from 'react'
import './Recursos.css'

// Dependências Externas
import 'animate.css'
import ReactWOW from 'react-wow'

export default (props) => (
  <section className="recursos padding-section">
    <div className="container pt-5 pb-5">
      <ReactWOW animation="fadeIn">
        <div className="text-center title-group mb-5">
          <h3>Recursos do Sistema</h3>
          <hr className="divisor" />
          <p>
            Listamos abaixo alguns dos principais recursos do Sistema de
            Gerenciamento de Frotas Carrorama Fleet.
          </p>
        </div>
      </ReactWOW>
      <div className="row">
        <ReactWOW animation="flipInY">
          <div className="col-md-4 col-lg-3 mt-4">
            <div className="card">
              <div className="card-body text-center">
                <div className="recursos-icon mb-3">
                  <i className="fas fa-map-marker-alt"></i>
                </div>
                <h5 className="card-title">Localização em Tempo Real</h5>
                <p className="card-text">
                  Acompanhe em tempo real a localização exata de todos os
                  veículos da sua frota.
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="flipInY">
          <div className="col-md-4 col-lg-3 mt-4">
            <div className="card">
              <div className="card-body text-center">
                <div className="recursos-icon mb-3">
                  <i className="fas fa-chart-bar"></i>
                </div>
                <h5 className="card-title">Relatórios</h5>
                <p className="card-text">
                  Relatórios completos para melhor análise e auxiliar nas
                  tomadas de decisões críticas para melhorar a eficiência da sua
                  frota.
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="flipInY">
          <div className="col-md-4 col-lg-3 mt-4">
            <div className="card">
              <div className="card-body text-center">
                <div className="recursos-icon mb-3">
                  <i className="fas fa-tachometer-alt"></i>
                </div>
                <h5 className="card-title">Controle de KM</h5>
                <p className="card-text">
                  Acesse a quilometragem de todos os veículos da sua frota com
                  poucos cliques.
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="flipInY">
          <div className="col-md-4 col-lg-3 mt-4">
            <div className="card">
              <div className="card-body text-center">
                <div className="recursos-icon mb-3">
                  <i className="fas fa-car"></i>
                </div>
                <h5 className="card-title">Parâmetros do Carro</h5>
                <p className="card-text">
                  Relatórios de consumo, como: tempo em que o veículo esteve
                  ligado, consumo de combustível, total de quilometragem rodada,
                  entre outros.
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="flipInY">
          <div className="col-md-4 col-lg-3 mt-4">
            <div className="card">
              <div className="card-body text-center">
                <div className="recursos-icon mb-3">
                  <i className="fas fa-bell"></i>
                </div>
                <h5 className="card-title">Alertas</h5>
                <p className="card-text">
                  Excesso de velocidade, Frenagem brusca, Aceleração brusca,
                  Ignição ligada e Ignição desligada.
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="flipInY">
          <div className="col-md-4 col-lg-3 mt-4">
            <div className="card">
              <div className="card-body text-center">
                <div className="recursos-icon mb-3">
                  <i className="fas fa-money-bill-wave"></i>
                </div>
                <h5 className="card-title">Controle de Gastos</h5>
                <p className="card-text">
                  Controle de Gastos com multas, abastecimento e outras
                  eventualidades.
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="flipInY">
          <div className="col-md-4 col-lg-3 mt-4 em-desenvolvimento">
            <div className="card">
              <div className="card-body text-center">
                <div className="recursos-icon mb-3">
                  <i className="fas fa-user"></i>
                </div>
                <h5 className="card-title">Comportamento de Motorista</h5>
                <p className="card-text">
                  Saiba como seu motorista está dirigindo seu veículo. Frenagens
                  bruscas, alta RPM, velocidade excessiva, entre outros.
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
        <ReactWOW animation="flipInY">
          <div className="col-md-4 col-lg-3 mt-4 em-desenvolvimento">
            <div className="card">
              <div className="card-body text-center">
                <div className="recursos-icon mb-3">
                  <i className="fas fa-bolt"></i>
                </div>
                <h5 className="card-title">Diagnósticos de Falhas</h5>
                <p className="card-text">
                  Identifique problemas mecânicos ou elétricos antes que eles
                  gerem maiores despesas na manutenção.
                </p>
              </div>
            </div>
          </div>
        </ReactWOW>
      </div>
    </div>
  </section>
);