function CPF(str) {
    // 999.999.999-99
    return str
        ? str
            .replace(/\D/g, '')
            .slice(0, 11)
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3}).(\d{3})(\d)/, '$1.$2.$3')
            .replace(/(\d{3}).(\d{3}).(\d{3})(\d{1,2})/, '$1.$2.$3-$4')
            .replace(/(\d{3}).(\d{3}).(\d{3})-(\d{2})(\d)/, '$1.$2.$3-$4')
        : str
}

function Telefone(str) {
    // (99) 99999-9999
    // (99) 9999-9999
    return str
        ? str
            .replace(/\D/g, '')
            .slice(0, 11)
            .replace(/^(\d{2})(\d{5})(\d{4})(\d)/, '($1) $2-$3')
            .replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
            .replace(/^(\d{2})(\d{4})(\d)/, '($1) $2-$3')
            .replace(/^(\d{2})(\d)/, '($1) $2')
        : str
}

function CNPJ(str) {
    // 99.999.999/9999-99
    return str
        ? str
            .replace(/\D/g, '')
            .slice(0, 14)
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{2}).(\d{3})(\d)/, '$1.$2.$3')
            .replace(/(\d{2}).(\d{3}).(\d{3})(\d)/, '$1.$2.$3/$4')
            .replace(/(\d{2}).(\d{3}).(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5')
            .replace(
                /(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})(\d)/,
                '$1.$2.$3/$4-$5'
            )
        : str
}

function CEP(str) {
    // 99999-999
    return str
        ? str
            .replace(/\D/g, '')
            .slice(0, 8)
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(\d{5})-(\d{3})(\d)/, '$1-$2')
        : str
}

function Numero(str) {
    return str ? str.replace(/\D/g, '') : str
}

export {
    CPF,
    Telefone,
    CNPJ,
    CEP,
    Numero,
}